import { CustomerAppUser } from '../providers/types/providerTypes'

declare global {
  interface Window {
    analytics: {
      load: (key: string) => void
      identify: (userId: string, traits?: object) => void
      track: (event: string, properties?: object) => void
      page: (name: string, traits?: object) => void
      group: (groupId: string, traits?: object) => void
    }
  }
}

export interface TraitsWithCustomerName {
  customerName?: string
}

function segmentCall(segmentFn: () => void): void {
  if (process.env.NODE_ENV === 'development') {
    console.log('blocked tracking call: ' + segmentFn)
    return
  }

  segmentFn()
}

/**
 * Initialize segment. Only active in deployed builds (will not run locally unless running a production variant)
 */
export function segmentLoad(): void {
  segmentCall(() => window.analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY || ''))
}

/**
 * Identify a user in Segment
 * @param {string} userId Unique user ID
 * @param {TraitsWithCustomerName} traits user traits
 */
export function segmentIdentify(userId: string, traits?: TraitsWithCustomerName): void {
  segmentCall(() => window.analytics.identify(userId, sanitizeCustomerNameForMixpanel(traits)))
}

/**
 * Track an event in Segment
 * @param {string} eventTitle Segment tracked event title
 * @param {object} properties event properties
 */
export function segmentTrack(eventTitle: string, properties?: object): void {
  segmentCall(() => window.analytics.track(eventTitle, sanitizeCustomerNameForMixpanel(properties)))
}

export function segmentUserTrack(eventTitle: string, user: CustomerAppUser, properties?: object): void {
  segmentTrack(eventTitle, { ...user, ...properties })
}

/**
 * Track an page load event in Segment
 * @param {string} pageTitle Segment tracked page title
 * @param {TraitsWithCustomerName} traits user traits
 */
export function segmentPage(pageTitle: string, traits?: TraitsWithCustomerName): void {
  segmentCall(() => window.analytics.page(pageTitle, sanitizeCustomerNameForMixpanel(traits)))
}

export function segmentUserPage(pageTitle: string, user: CustomerAppUser, properties?: object): void {
  segmentPage(pageTitle, { ...user, ...properties })
}

/**
 * https://segment.com/docs/connections/spec/group/
 * @param {string} customerName The customer name
 */
export function segmentGroup(customerName: string): void {
  const sanitizedTraits = sanitizeCustomerNameForMixpanel({ customerName }) as TraitsWithCustomerName
  segmentCall(() =>
    window.analytics.group(
      // 'customerName' is our segment/Mixpanel group key
      // Many examples use an ID value but we use a name string for now
      sanitizedTraits.customerName as string,
      {
        ...sanitizedTraits, // 'customerName' is our segment/Mixpanel group key and must be present in these traits
        name: customerName, // Mixpanel group name - this allows the Mixpanel UI to display a readable name
      }
    )
  )
}

/**
 * Clean up the customer name for mixpanel.
 * We've seen issues with whitespace and & characters
 * If we can find a better way to key to a Customer we can change it here but we
 * started off with a specific ask to use Customer Name.
 *
 * Exported primarily for testing, not expected to be used outside this file.
 *
 * @param {TraitsWithCustomerName} traits Object containing a customerName intended to end up in Mixpanel
 */
export function sanitizeCustomerNameForMixpanel(
  traits: TraitsWithCustomerName | undefined
): TraitsWithCustomerName | undefined {
  if (traits === undefined) return undefined
  if (!traits.customerName) return traits

  let name = traits.customerName
  name = name.replace(/ /g, '_')
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/\&/g, 'and') // This is not a useless escape

  return { ...traits, customerName: name }
}
