import Amplify, { Auth } from 'aws-amplify'
import React, { ReactElement, useCallback, useEffect, useReducer } from 'react'

import { AuthContextData, AuthProviderState, AuthState, AuthTokens, AuthUser, Credentials } from './types/providerTypes'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION || '',
    userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
})

export const AuthContext = React.createContext<AuthContextData>({
  authState: AuthState.LOGGED_OUT,
  authUser: undefined,
  authenticate: () => Promise.reject(),
  signIn: () => Promise.reject(),
  signOut: () => Promise.reject(),
  tokens: () => Promise.reject(),
  signUp: () => Promise.reject(),
  signUpVerify: () => Promise.reject(),
  sendSignUpCode: () => Promise.reject(),
  forgotPassword: () => Promise.reject(),
  forgotPasswordSubmit: () => Promise.reject(),
  updatePassword: () => Promise.reject(),
  verifyEmail: () => Promise.reject(),
  verifyEmailSubmit: () => Promise.reject(),
})

export function useAuth(): AuthContextData {
  return React.useContext(AuthContext)
}

export default function AuthProvider(props: object): ReactElement | null {
  const [{ ready, authState, authUser }, setState] = useReducer(
    (current: AuthProviderState, updates: Partial<AuthProviderState>): AuthProviderState => ({
      ...current,
      ...updates,
    }),
    {
      ready: false,
      authState: AuthState.LOGGED_OUT,
      authUser: undefined,
    }
  )

  useEffect(() => {
    ;(async (): Promise<void> => {
      let cognitoUser: AuthUser | undefined = undefined
      let state: AuthState
      try {
        cognitoUser = await Auth.currentAuthenticatedUser()
        state = cognitoUser && cognitoUser.attributes.email_verified ? AuthState.LOGGED_IN : AuthState.AUTHENTICATED
      } catch (e) {
        state = AuthState.LOGGED_OUT
        cognitoUser = undefined
      }

      setState({ ready: true, authState: state, authUser: cognitoUser })
    })()
  }, [])

  const authenticate = useCallback(async (credentials: Credentials): Promise<AuthUser> => {
    const { username, password } = credentials

    if (!username || !password) {
      throw new Error('invalid credentials')
    }

    try {
      const cognitoUser = await Auth.signIn(username, password)
      setState({ authState: AuthState.AUTHENTICATED, authUser: cognitoUser })
      return cognitoUser
    } catch (e) {
      console.error('authenticate failure:', e)
      throw e
    }
  }, [])

  const signIn = useCallback(
    async (credentials?: Credentials): Promise<void> => {
      if (!credentials && authUser) {
        setState({ authState: AuthState.LOGGED_IN })
        return
      }

      const { username, password } = credentials || {}
      if (!username || !password) {
        throw new Error('invalid credentials')
      }

      try {
        const cognitoUser = await Auth.signIn(username, password)
        setState({ authState: AuthState.LOGGED_IN, authUser: cognitoUser })
      } catch (e) {
        console.error('login failure:', e)
        setState({ authState: AuthState.LOGGED_OUT, authUser: undefined })
        throw e
      }
    },
    [authUser]
  )

  const signOut = useCallback(async (): Promise<void> => {
    await Auth.signOut()
    setState({ authState: AuthState.LOGGED_OUT, authUser: undefined })
  }, [])

  const tokens = useCallback(async (): Promise<AuthTokens> => {
    const session = await Auth.currentSession()
    return {
      id: session.getIdToken().getJwtToken(),
      access: session.getAccessToken().getJwtToken(),
      refresh: session.getRefreshToken().getToken(),
    }
  }, [])

  const signUp = useCallback(async (credentials: Credentials): Promise<void> => {
    await Auth.signUp(credentials)
  }, [])

  const signUpVerify = useCallback(async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code)
  }, [])

  const sendSignUpCode = useCallback(async (username: string): Promise<void> => {
    await Auth.resendSignUp(username)
  }, [])

  const forgotPassword = useCallback(async (username: string): Promise<void> => {
    await Auth.forgotPassword(username)
  }, [])

  // prettier-ignore
  const forgotPasswordSubmit = useCallback(
    async (username: string, code: string, newPassword: string): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
  }, [])

  const updatePassword = useCallback(
    async (currentPass: string, newPass: string): Promise<void> => {
      if (!authUser) {
        throw new Error('Not currently authenticated')
      }

      await Auth.changePassword(authUser, currentPass, newPass)
    },
    [authUser]
  )

  const verifyEmail = useCallback(async (): Promise<void> => {
    if (!authUser) {
      throw new Error('no current authenticated user')
    }

    await Auth.verifyCurrentUserAttribute('email')
  }, [authUser])

  const verifyEmailSubmit = useCallback(
    async (code: string): Promise<void> => {
      if (!authUser) {
        throw new Error('no current authenticated user')
      }

      await Auth.verifyCurrentUserAttributeSubmit('email', code)
    },
    [authUser]
  )

  const authValues = React.useMemo(() => {
    return {
      authState,
      authUser,
      authenticate,
      signIn,
      signOut,
      tokens,
      signUp,
      signUpVerify,
      sendSignUpCode,
      forgotPassword,
      forgotPasswordSubmit,
      updatePassword,
      verifyEmail,
      verifyEmailSubmit,
    }
  }, [
    authState,
    authUser,
    authenticate,
    signIn,
    signOut,
    tokens,
    signUp,
    signUpVerify,
    sendSignUpCode,
    forgotPassword,
    forgotPasswordSubmit,
    updatePassword,
    verifyEmail,
    verifyEmailSubmit,
  ])

  return !ready ? null : <AuthContext.Provider value={authValues} {...props} />
}
