/**
 * Create a root element required by Sisense and attach it to the DOM.
 *
 * Run this from index.tsx, before kicking off any rendering.
 */
export const connectSisenseToDOM = (): void => {
  const sisenseContainer = document.createElement('div')
  sisenseContainer.id = 'sisenseApp'

  document.querySelector('body')?.appendChild(sisenseContainer)

  const appRoot = document.getElementById('customer-app')
  if (appRoot != null) {
    sisenseContainer.appendChild(appRoot)
  }
}
