export const loadAsScript = (src, type = 'text/javascript'): Promise<void> =>
  new Promise((resolve) => {
    const script = Object.assign(document.createElement('script'), {
      type,
      src,
      async: true,
      onload: resolve,
    })
    document.body.appendChild(script)
  })
