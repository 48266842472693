import { v4 as uuid } from 'uuid'

import { loadAsScript } from '../utils/loadAsScript'

/**
 * Initializes Sisense Embed within the application by authenticating to Sisense via JWT SSO and then loads the Embed SDK.
 *
 * @param {string} server Sisense server url
 * @param {string} token Sisense JWT obtained from the Customer App service
 */
export const loadEmbedSDK = async (server: string, token: string): Promise<void> => {
  await loadAsScript(`${server}/jwt?jwt=${token}&return_to=${encodeURI(`/js/frame.js?v=${uuid()}`)}`)

  const { SisenseFrame, enums } = window['sisense.embed']

  if (!SisenseFrame || !enums) {
    throw new Error(
      `Failed to load Sisense Embed SDK. Please verify that Sisense is running at ${server} and that you're authenticated.`
    )
  }
}
