const fetchJson = async <T>(url: string, config?: RequestInit): Promise<T> => {
  const jsonConfig = { ...config, headers: { ...config?.headers, ...{ Accept: 'application/json' } } }
  const response = await fetch(url, jsonConfig)

  if (response.status < 200 || response.status >= 300) {
    throw new Error(`Request failed with response ${response.status}: ${response.statusText}`)
  }

  return await response.json()
}

export default fetchJson
