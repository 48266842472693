import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { SERVICE_URL } from '../providers/ServiceContext'

export function createClient(bearerTokenFn: () => Promise<string>): ApolloClient<NormalizedCacheObject> {
  const bearerTokenLink = setContext(async (_request, { headers }) => {
    try {
      const token = await bearerTokenFn()
      return { headers: { ...headers, Authorization: token ? `Bearer ${token}` : '' } }
    } catch (e) {
      console.error('Unable to retrieve authentication tokens')
    }
  })

  return new ApolloClient<NormalizedCacheObject>({
    cache: new InMemoryCache(),
    // note: the order of links are important
    link: ApolloLink.from([
      bearerTokenLink,
      new HttpLink({
        uri: SERVICE_URL ? `${SERVICE_URL}/graphql` : 'http://localhost:4000/graphql',
      }),
    ]),
  })
}

export function excludeTypename<T>(data: T): T {
  if (!data || !(data instanceof Object)) {
    return data
  }

  if (Array.isArray(data)) {
    return (data.map(excludeTypename) as unknown) as T
  }

  const cleaned = {}
  Object.keys(data).forEach((key) => {
    if (key !== '__typename') {
      cleaned[key] = excludeTypename(data[key])
    }
  })

  return cleaned as T
}
