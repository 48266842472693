import React, { ReactElement } from 'react'

import styles from './MaintenancePage.module.scss'

type MaintenancePageProps = {
  message: string | undefined
}
/**
 * The profile page for customer app users
 */
export default function MaintenancePage({ message }: MaintenancePageProps): ReactElement {
  return (
    <div className={styles.maintenancePage}>
      <div className={styles.row}>
        <div className={`${styles.column} ${styles.logoContainer}`}>
          <img
            alt="Wiser Solutions Inc."
            src="https://www.wiser.com/wp-content/uploads/2019/07/wiser-logo-notagline-white-290x89@2xC.png"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <h1 className={styles.lightText}>Wiser is currently down for scheduled maintenance.</h1>
          <p className={styles.lightText}>
            Thank you for your patience. {message || "We'll be back as soon as possible"}.
          </p>
        </div>
      </div>
    </div>
  )
}
