import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import './index.css'
import AuthProvider from './providers/AuthContext'
import ServiceProvider from './providers/ServiceContext'
import { connectSisenseToDOM } from './sisense/connectSisenseToDOM'
import { segmentLoad } from './utils/segment'

connectSisenseToDOM()
segmentLoad()

ReactDOM.render(
  <Router>
    <AuthProvider>
      <ServiceProvider>
        <App />
      </ServiceProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('customer-app')
)
