import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Row, Spin } from 'antd'
import React, { ReactElement, Suspense, useEffect, useRef } from 'react'

import { useService } from './providers/ServiceContext'
import { CustomerAppUser } from './providers/types/providerTypes'
import { segmentIdentify, segmentUserTrack } from './utils/segment'

const Authenticate = React.lazy(() => import('./components/auth/AuthPage'))
const Main = React.lazy(() => import('./components/MainLayout'))

/**
 * Render the application or authentication pages based on the presence of an authenticated user
 */
export default function App(): ReactElement | null {
  const { user } = useService()
  const userRef = useRef<CustomerAppUser | undefined>()

  useEffect(() => {
    if (user) {
      if (!userRef.current) {
        segmentIdentify(user.email, user)
        segmentUserTrack('User Signed In', user)
      }
      userRef.current = user
    } else if (userRef.current) {
      segmentUserTrack('User Signed Out', userRef.current)
      userRef.current = undefined
    }
  }, [user])

  return (
    <Suspense
      fallback={
        <Row align="middle" justify="center" style={{ height: '100vh' }}>
          <Spin tip="Loading..." indicator={<Loading3QuartersOutlined style={{ fontSize: 32 }} spin />} />
        </Row>
      }
    >
      {user ? <Main /> : <Authenticate />}
    </Suspense>
  )
}
