export type AuthUser = {
  username: string
  attributes: {
    email_verified: boolean
  }
  signInUserSession: {
    getAccessToken: () => AccessToken
  }
}

export type AccessToken = {
  payload: object
}

export enum AuthState {
  AUTHENTICATED,
  LOGGED_OUT,
  LOGGED_IN,
}

export type Credentials = {
  username: string
  password: string
}

export type AuthTokens = {
  id: string
  access: string
  refresh: string
}

export type UserRoles = {
  isEmployeeAdmin: boolean
  isUserAdmin: boolean
}

export type CustomerAppUser = {
  id: string
  email: string
  roles: UserRoles
  firstName?: string
  lastName?: string
  favoriteDashboard?: string
  customerName?: string
  customerIdentity?: string
}

export type AuthContextData = {
  authState: AuthState
  authUser: AuthUser | undefined
  authenticate: (credentials: Credentials) => Promise<AuthUser>
  signIn: (credentials?: Credentials) => Promise<void>
  signOut: () => Promise<void>
  tokens: () => Promise<AuthTokens>
  signUp: (credentials: Credentials) => Promise<void>
  signUpVerify: (username: string, code: string) => Promise<void>
  sendSignUpCode: (username: string) => Promise<void>
  forgotPassword: (username: string) => Promise<void>
  forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<void>
  updatePassword: (currentPass: string, newPass: string) => Promise<void>
  verifyEmail: () => Promise<void>
  verifyEmailSubmit: (code: string) => Promise<void>
}

export type AuthProviderState = {
  ready: boolean
  authState: AuthState
  authUser: AuthUser | undefined
}

export type ServiceContextData = {
  ready: boolean
  user: CustomerAppUser | undefined
  updateUser: (updates: Partial<CustomerAppUser>) => Promise<void>
}

export type ServiceProviderState = {
  ready: boolean
  maintenance: MaintenanceData | undefined
  user: CustomerAppUser | undefined
}

export type MaintenanceData = {
  active: boolean
  message?: string
}
