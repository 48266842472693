import { Loading3QuartersOutlined } from '@ant-design/icons'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { Row, Spin } from 'antd'
import React, { ReactElement, useEffect, useReducer, useRef } from 'react'

import MaintenancePage from '../components/MaintenancePage'
import { loadEmbedSDK } from '../sisense/loadEmbedSDK'
import { createClient, excludeTypename } from '../utils/apollo'
import fetchJson from '../utils/fetchJson'

import { useAuth } from './AuthContext'
import {
  AuthState,
  CustomerAppUser,
  MaintenanceData,
  ServiceContextData,
  ServiceProviderState,
} from './types/providerTypes'

export const SERVICE_URL = process.env.REACT_APP_CUSTOMER_APP_SERVICE_URL
export const SISENSE_URL = process.env.REACT_APP_SISENSE_SERVER || ''

const GQL_GET_USER = gql`
  query GetUser($email: String!) {
    user(email: $email) {
      id
      email
      firstName
      lastName
      favoriteDashboard
      sisenseToken {
        id
      }
      roles {
        isEmployeeAdmin
        isUserAdmin
      }
      customerInfo {
        identity
        name
      }
    }
  }
`

const GQL_UPDATE_USER = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      id
      email
      firstName
      lastName
      favoriteDashboard
    }
  }
`

export const ServiceContext = React.createContext<ServiceContextData>({
  ready: false,
  user: undefined,
  updateUser: () => Promise.reject(),
})

export function useService(): ServiceContextData {
  return React.useContext(ServiceContext)
}

export function useUser(): CustomerAppUser {
  const { user } = React.useContext(ServiceContext)

  if (!user) {
    console.error('Unsupported use of hook from an unauthenticated location. ')
    throw new Error('Invalid Application State')
  }

  return user
}

export default function ServiceProvider(props: object): ReactElement {
  const { authState, authUser, tokens } = useAuth()
  const clientRef = useRef<ApolloClient<NormalizedCacheObject>>()
  const [{ ready, maintenance, user }, setState] = useReducer(
    (current: ServiceProviderState, updates: Partial<ServiceProviderState>): ServiceProviderState => ({
      ...current,
      ...updates,
    }),
    {
      ready: false,
      maintenance: undefined,
      user: undefined,
    }
  )

  useEffect(() => {
    ;(async (): Promise<void> => {
      try {
        const maintenanceState = await fetchJson<MaintenanceData>(`${SERVICE_URL}/maintenance`)
        setState({ maintenance: maintenanceState })
      } catch (e) {
        console.error('Unable to retrieve maintenance information', e)
        //ISC-238: defaulting maintenance mode to active if service (or ES backing it) is unreachable
        setState({ maintenance: { active: true, message: undefined } })
      }
    })()
  }, [])

  useEffect(() => {
    setState({ ready: false })

    if (!authUser || authState !== AuthState.LOGGED_IN) {
      setState({ ready: true, user: undefined })
      return
    }

    const apiClient = createClient(async () => (await tokens()).id)
    clientRef.current = apiClient

    const authedEmail = authUser.username
    const init = async (): Promise<void> => {
      try {
        const serviceUser = (
          await apiClient.query({
            query: GQL_GET_USER,
            variables: {
              email: authedEmail,
            },
          })
        ).data.user

        if (serviceUser) {
          await loadEmbedSDK(SISENSE_URL, serviceUser.sisenseToken.id)
        } else {
          throw new Error(`unable to retrieve app user for email ${authedEmail}`)
        }

        const { id, email, firstName, lastName, favoriteDashboard, roles, customerInfo } = excludeTypename(serviceUser)
        setState({
          ready: true,
          user: {
            id,
            email,
            roles,
            firstName,
            lastName,
            favoriteDashboard,
            customerIdentity: customerInfo?.identity,
            customerName: customerInfo?.name,
          },
        })
      } catch (e) {
        console.error('failed to initialize application', e)
        // TODO: error state
        setState({ ready: true, user: undefined })
      }
    }
    init()
  }, [authState, authUser, tokens])

  const updateUser = async (updates: Partial<CustomerAppUser>): Promise<void> => {
    if (!clientRef.current || !user) {
      return
    }

    const { id, email, firstName, lastName, favoriteDashboard } = user
    const userInput = { ...{ id, email, firstName, lastName, favoriteDashboard }, ...updates }

    const { data } = await clientRef.current.mutate({
      mutation: GQL_UPDATE_USER,
      variables: {
        user: userInput,
      },
    })

    setState({ user: { ...user, ...excludeTypename(data.updateUser) } })
  }

  if (!ready || !maintenance) {
    return (
      <Row align="middle" justify="center" style={{ height: '100vh' }}>
        <Spin tip="Loading..." indicator={<Loading3QuartersOutlined style={{ fontSize: 32 }} spin />} />
      </Row>
    )
  }

  const serviceValues = { ready, user, updateUser }

  return maintenance.active ? (
    <MaintenancePage message={maintenance.message} />
  ) : (
    <ServiceContext.Provider value={serviceValues} {...props} />
  )
}
